import React from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
const SECTION_INTRO_ID = 'intro';

import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionLocations,
  SectionLocations2,
  SectionBlog,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  SectionIntro,
  Footer,
  SectionBlogOne,
  SectionHowItWorksSecond,
  SectionInformation,
  SectionBlogImage,
  SectionPriceCards,
  MainContentSection,
  SectionExperienceOffered,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/yogatimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/yogatimeTwitter-600x314.jpg';
import css from './LandingPage.module.css';
import { useEffect } from 'react';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    currentUserListingFetched,
    products,
    productsLoading,
    productsError,
  } = props;
  // console.log('pro', products);
  const searchParams = new URLSearchParams(location.search);
  const ref = searchParams.get('ref');

  useEffect(() => {
    if (ref && typeof window != 'undefined') {
      localStorage.setItem('ref', ref);
    }
  }, [ref]);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar absoluteTopbar>
          {/* <TopBarBanner /> */}
          <TopbarContainer isLandingPage={true} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero
              className={css.hero}
              history={history}
              location={location}
              introId={SECTION_INTRO_ID}
              intl={intl}
            />
          </div>
          <ul className={css.sections}>
            <li className={css.section} id={SECTION_INTRO_ID} style={{ backgroundColor: 'white' }}>
              <div className={css.sectionContentFirstChild}>
                <SectionIntro />
              </div>
            </li>

            <li className={css.section} style={{ background: '#fcfcfc', paddingTop: 0 }}>
              <div
                className={css.sectionContent}
                style={{ background: '#fcfcfc', marginTop: '10px' }}
              >
                <SectionExperienceOffered />
              </div>
            </li>
            <li className={css.section} style={{ background: '#fcfcfc', paddingTop: 0 }}>
              <div
                className={css.sectionContent}
                style={{ background: '#fcfcfc', marginTop: '10px' }}
              >
                {/* ///community leaders */}
                <SectionBlogOne />
              </div>
            </li>
            {/* <li className={css.section} style={{ backgroundColor: 'white' }}>
              <div className={css.sectionMainContent}>
                <MainContentSection />
              </div>
            </li> */}
            {/* <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionLocations2 />
              </div>
            </li> */}

            {/* <li className={css.section} style={{ background: '#fcfcfc', paddingTop: 0 }}>
              <div
                className={css.sectionContent}
                style={{ background: '#fcfcfc', marginTop: '10px' }}
              >
                <SectionPriceCards />
              </div>
            </li> */}

            <li className={css.section} style={{ background: '#fcfcfc', paddingTop: 0 }}>
              <div
                className={css.sectionContent}
                style={{ background: '#fcfcfc', marginTop: '50px' }}
              >
                <SectionBlog listings={products} loading={productsLoading} error={productsError} />
              </div>
            </li>
            <li className={css.section} style={{ background: '#fcfcfc', paddingTop: 0 }}>
              <div
                className={css.sectionContent}
                style={{ background: '#fcfcfc', marginTop: '50px' }}
              >
                <SectionLocations />
              </div>
            </li>
            {/* <li className={css.sectionHowSecond} style={{ paddingTop: 0 }}>
              <div className={css.sectionContentHowItWorks}>
                <SectionInformation
                  currentUserListing={currentUserListing}
                  currentUserListingFetched={currentUserListingFetched}
                />
              </div>
            </li> */}

            <li className={css.sectionHowSecond} style={{ paddingTop: 0 }}>
              <div className={css.sectionContentHowItWorks}>
                <SectionBlogImage />
              </div>
            </li>
            {/* <li className={css.section} style={{ background: '#fcfcfc', paddingTop: 0 }}>
              <div className={css.sectionContent}>
                <SectionHowItWorks
                  currentUserListing={currentUserListing}
                  currentUserListingFetched={currentUserListingFetched}
                />
              </div>
            </li> */}
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched } = state.user;
  const { productIds, productsLoading, productsError } = state.LandingPage;
  const products = productIds?.map(t => {
    const ref = { id: t, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  });
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    products,
    productsLoading,
    productsError,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
